<template>
  <div>
    <slot v-bind="defaultSlotProps" />
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    value: {
      type: Date
    },
    unit: {
      type: String,
      validator(val) {
        return ['day', 'week', 'month', 'year'].includes(val);
      }
    }
  },
  data() {
    return {
      current: moment(this.value)
        .locale(`${this.$t('notifications.language')}`)
        .startOf(this.unit)
    };
  },
  computed: {
    defaultSlotProps() {
      return {
        page: {
          current: this.current.toDate(),
          format: format =>
            moment(this.current)
              .locale(`${this.$t('notifications.language')}`)
              .format(format)
        },
        methods: {
          next: this.onNextPage,
          back: this.onBackPage,
          today: this.onToday
        }
      };
    }
  },
  watch: {
    current: 'onInput',
    unit(unit) {
      this.current = moment()
        .locale(`${this.$t('notifications.language')}`)
        .startOf(unit);
    }
  },
  methods: {
    onToday() {
      this.current = moment().locale(`${this.$t('notifications.language')}`);
    },
    onNextPage() {
      this.current = moment(this.current)
        .locale(`${this.$t('notifications.language')}`)
        .add(1, this.unit);
    },
    onBackPage() {
      this.current = moment(this.current)
        .locale(`${this.$t('notifications.language')}`)
        .subtract(1, this.unit);
    },
    onInput(mDate) {
      this.$emit('input', mDate.toDate());
    }
  }
};
</script>

<style></style>
