<template>
  <modal
    :name="getTaskModalName"
    height="auto"
    scrollable
    styles="overflow: visible;"
    @closed="closed"
    v-on="$listeners"
  >
    <div v-if="task" class="p-5">
      <div class="flex justify-end divide-x">
        <base-dropdown width="small" position="bottom-right" class="pr-4">
          <base-button
            slot="toggle"
            color="text"
            wide="p-1"
            icon="dotsHorizontal"
            class="outline-none self-center"
          />
          <base-dropdown-item danger @click="promptDelete"
            >Delete Task</base-dropdown-item
          >
        </base-dropdown>
        <div class="flex pl-4">
          <base-button
            color="text"
            wide="p-1"
            icon="close"
            icon-size="20"
            @click="$modal.hide('waterfall-task')"
          />
        </div>
      </div>
      <div>
        <waterfall-board-form-task
          v-model="taskForm"
          :date-disabled="isGroup"
          class="mt-1"
          :task="task"
          @changed="updateTask"
        />
      </div>
      <!-- 
        // * Task comments & activities
       -->
      <TaskCommentActivityWrapper :task-id="task.id" />
    </div>
    <div v-else-if="loading" style="height: 400px;" class="has-centered-item">
      <base-spinner size="60" />
    </div>
    <div v-else style="height: 400px;" class="has-centered-item">
      <div class="text-center">
        <p class="text-2xl">Task no found</p>
        <p class="text-gray-label">It might have been removed from board</p>
      </div>
    </div>
  </modal>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import BoardDialogConfirm from '@/components/board/board-dialog-confirm';
import waterfallBoardFormTask from '../waterfall-board/waterfall-board-form-task.vue';
import TaskCommentActivityWrapper from '@/components/comments/task-comment-activity-wrapper.vue';

const GROUP = 1;

export default {
  components: { waterfallBoardFormTask, TaskCommentActivityWrapper },
  props: {
    task: Object,
    loading: Boolean
  },
  data() {
    return {
      taskForm: undefined
    };
  },
  computed: {
    getTaskModalName() {
      return 'waterfall-task';
    },
    isGroup() {
      return this.task.type === GROUP;
    }
  },
  watch: {
    task: {
      handler(val) {
        if (val) this.setForm(val);
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('tasks', ['setTask']),
    // modal events
    closed() {
      this.taskForm = undefined;
      this.$router.push({ query: { t: undefined } });
      this.$emit('update:task', undefined);
    },
    setForm(task) {
      const { startAt, endAt, progressInPercent, description, name } = task;
      const duration = moment(endAt).diff(startAt, 'days');
      this.taskForm = {
        startAt: new Date(startAt),
        duration,
        name,
        description,
        progress: progressInPercent
      };
    },
    updateTask: _.debounce(function(updated) {
      const form = this.taskForm;
      const { progress: progressInPercent, duration, ...task } = updated;
      let endAt;
      const getEndDate = len => moment(form.startAt).add(len, 'days');
      if (duration) endAt = getEndDate(duration);

      if (task.startAt) endAt = getEndDate(form.duration);

      if (endAt) endAt = endAt.toISOString();
      this.setTask({ id: this.task.id, progressInPercent, endAt, ...task });
    }, 500),
    promptDelete() {
      this.$modal.show(
        BoardDialogConfirm,
        {
          title: 'Delete task',
          description: 'Are you sure you want to delete this task?',
          button: 'Delete Task',
          confirmAction: 'tasks/removeTask',
          confirmData: this.task.id,
          onConfirm: () => this.$modal.hide(this.getTaskModalName)
        },
        {
          height: 'auto',
          maxWidth: 430,
          adaptive: true
        }
      );
    }
  }
};
</script>

<style></style>
