







import Vue, { PropType } from 'vue';
import moment from 'moment';
import {
  GanttStatic,
  GanttTemplates
} from '@/libs/gantt_7/codebase/dhtmlxgantt';

interface ChartTask {
  id: string;
}

interface ChartLink {
  id: string;
}

interface ChartData {
  tasks: ChartTask[];
  links: ChartLink[];
}

interface LocaleDate {
  date: {
    [unit: string]: string[];
  };
}

interface Task {
  $target: (number | string)[];
}

interface Column {
  name: string;
  label: string;
  width: number;
  align: string;
}

const dateTh = {
  month_full: [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฏาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม'
  ],
  month_short: [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.'
  ],
  day_full: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์'],
  day_short: ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส']
};
const dateEn = {
  month_full: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  month_short: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ],
  day_full: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ],
  day_short: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
};

const SCRIPT_SRC = '/scripts/dhtmlx/codebase/dhtmlxgantt.js';

export default Vue.extend({
  props: {
    chartData: {
      type: Object as PropType<ChartData>,
      default: () => ({ tasks: [], links: [] })
    },
    config: {
      type: Object,
      default: () => ({})
    },
    templates: {
      type: Object as PropType<Partial<GanttTemplates>>,
      default: () => ({})
    },
    plugins: {
      type: Object,
      default: () => ({})
    },
    events: {
      type: Object
    },
    showToday: Boolean,
    locale: {
      type: String as PropType<'en' | 'th'>,
      default: 'en'
    }
  },
  data() {
    return {
      gantt: {} as GanttStatic,
      marker: '',
      isParsed: false
    };
  },
  computed: {
    chart(): HTMLElement {
      return this.$refs['gantt-chart'] as HTMLElement;
    },
    withWorkTimeTemplates(): Partial<GanttTemplates> {
      if (!this.showToday) return this.templates;
      const scaleCss = 'gantt-scale-cell';
      const weekendCss = 'gantt-cell--weekend';
      return {
        ...this.templates,
        scale_cell_class() {
          return scaleCss;
        },
        timeline_cell_class(_, date: Date) {
          const canHighlight = date.getDay() == 0 || date.getDay() == 6;
          if (canHighlight) {
            return weekendCss;
          }
          return '';
        }
      };
    },
    getLocale() {
      const customThLocale: LocaleDate = {
        date: this.$i18n.locale === 'en' ? dateEn : dateTh
      };
      return customThLocale;
    },
    linkFormatter(): { format: (link: unknown) => string } {
      const durationFormatter = this.gantt.ext.formatters.durationFormatter({
        enter: 'day',
        store: 'day',
        format: 'auto',
        short: true
      });
      return this.gantt.ext.formatters.linkFormatter({
        durationFormatter
      });
    }
  },
  async mounted() {
    await this.loadScript();
    if (!this.gantt.version) {
      this.createGantt();
    }
  },
  beforeDestroy() {
    this.gantt.destructor();
    this.gantt = {} as GanttStatic;
    this.marker = '';
  },
  watch: {
    chartData: 'onTasksChanged',
    config(config) {
      const isChartElementExisted = this.chart.childElementCount > 0;
      if (this.gantt.config) this.setConfig(config);
      if (isChartElementExisted) {
        this.setColumns(config.columns);
        this.resetLayout();
      }
    },
    templates(templates) {
      this.gantt.templates = { ...this.gantt.templates, ...templates };
      this.resetLayout();
    },
    locale() {
      this.gantt.i18n.setLocale(this.getLocale);
      this.gantt.render();
    }
  },
  methods: {
    onTasksChanged(data: ChartData) {
      if (!data || !this.gantt.version) return;
      this.gantt.parse?.(data);
      this.addMarker();

      const ignorePath =
        this.$route.name === 'Board-Agile-Timeline-Task' ||
        this.$route.name === 'Board-Timeline-Task';
      if (!ignorePath) this.createGantt();
    },
    createGantt() {
      const gantt = window.Gantt.getGanttInstance({
        container: this.chart,
        config: this.config,
        data: this.chartData,
        plugins: {
          marker: this.showToday,
          ...this.plugins
        },
        events: this.events,
        locale: this.getLocale
      });
      Object.assign(gantt, this.withWorkTimeTemplates);
      this.gantt = gantt;
      this.addMarker();

      this.setColumns(this.config.columns);
      this.$emit('created', { gantt });
    },
    loadScript() {
      return new Promise<HTMLScriptElement | null>((resolve, reject) => {
        let shouldAppend = false;
        let elem: HTMLScriptElement | null = document.querySelector(
          `script[src="${SCRIPT_SRC}"]`
        );
        if (!elem) {
          elem = document.createElement('script');
          if (elem) {
            elem.type = 'text/javascript';
            elem.async = true;
            elem.src = SCRIPT_SRC;
            shouldAppend = true;
          }
        } else if (elem.hasAttribute('data-loaded')) {
          resolve(elem);
          return;
        }

        elem.addEventListener('error', reject);
        elem.addEventListener('abort', reject);
        elem.addEventListener('load', () => {
          elem?.setAttribute('data-loaded', 'true');
          resolve(elem);
        });

        if (shouldAppend) document.head.appendChild(elem);
      });
    },
    addMarker() {
      if (!this.showToday) {
        this.gantt.deleteMarker(this.marker);
      } else {
        if (this.marker) return this.gantt.renderMarkers();
        else {
          this.marker = this.gantt.addMarker({
            start_date: moment()
              .locale(`${this.$t('notifications.language')}`)
              .set('hours', 11)
              .toDate(),
            css: 'today'
          });
        }
      }
    },
    isWorkTime(date: Date) {
      return this.gantt.isWorkTime(date);
    },
    getColumns(column: Column) {
      const gantt = this.gantt;

      switch (column.name) {
        case 'predecessor': {
          const editor = {
            type: 'predecessor',
            map_to: 'auto',
            formatter: this.linkFormatter
          };
          const template = (task: Task) => {
            const links = task.$target;
            const labels: string[] = [];
            const formatLink: Parameters<typeof links.forEach>[0] = (_, i) => {
              const link = gantt.getLink(links[i]);
              const isFinishToStart = link.type === '0';
              let label = this.linkFormatter.format(link);
              if (isFinishToStart) {
                const FS_LOOKUP_REGEX = /(^\d+)(\+.+)?/;
                const INSERT_FS = `$1FS$2`;
                label = label.replace(FS_LOOKUP_REGEX, INSERT_FS);
              }
              labels.push(label);
            };
            links.forEach(formatLink);

            return `<span class="text-xs text-gray-caption">${labels.join(
              ', '
            )}</span>`;
          };
          return { ...column, editor, template };
        }
        case 'wbs':
          return {
            ...column,
            template: (e: Task): string => {
              const wbs = gantt.getWBSCode(e);
              return `<span class="text-xs text-gray-caption">${wbs}</span>`;
            }
          };

        default:
          return column;
      }
    },
    setColumns(columns: Column[]) {
      if (!columns) return;
      this.gantt.config.columns = columns.map(this.getColumns);
    },
    setConfig(config: { [config: string]: unknown }) {
      Object.assign(this.gantt.config, config);
    },
    resetLayout() {
      this.gantt.resetLayout();
      this.addMarker();
    }
  }
});
