<template>
  <div id="waterfall-board" class="flex-1 flex flex-col">
    <div class="flex flex-wrap justify-between py-4 px-8 -my-1">
      <wrapper-pagination-date
        v-model="paginationDate"
        class="py-1"
        :unit="paginationUnit"
      >
        <template #default="{page, methods}">
          <div class="flex flex-wrap items-center">
            <div class="flex">
              <base-button
                color="transparent"
                fa-icon="compress"
                @click="zoomToFit"
              />
              <base-button color="transparent" @click="methods.today">
                {{ $t('timeline.today') }}
              </base-button>
              <base-button
                wide="px-4"
                fa-icon="chevron-left"
                color="transparent"
                @click="methods.back"
              />
              <base-button
                wide="px-4"
                fa-icon="chevron-right"
                color="transparent"
                @click="methods.next"
              />
            </div>
            <strong class="text-2xl ml-5">
              {{ page.format(paginationFormat) }}
            </strong>
            <div class="pl-4">
              <base-button wide="board" @click="showCreate('create_task')">
                {{ $t('create_task') }}
              </base-button>
            </div>
          </div>
        </template>
      </wrapper-pagination-date>

      <div class="flex items-center -mx-2 py-1">
        <div class="px-2">
          <base-select
            v-model="zoom"
            placeholder="Status"
            placeholder-classes="text-black"
            :options="zoomOptions"
          />
        </div>
        <div class="relative px-2">
          <base-input-text
            v-model="queryString"
            icon-fa="search"
            :placeholder="$t('search')"
            type="text"
          />
          <div
            class="mx-3 my-2 absolute top-0 right-0 cursor-pointer z-10"
            @click="queryString = ''"
          >
            <base-icon v-if="queryString" :width="14" :height="14" name="close">
              <icon-close />
            </base-icon>
          </div>
        </div>

        <div class="px-2">
          <base-button
            wide="board"
            class="text-black bg-white"
            color="transparent"
            @click="toggleActivity"
          >
            {{ $t('activity') }}
          </base-button>
        </div>
      </div>
    </div>

    <div class="flex-1 relative">
      <div
        v-if="widthColumn > 450"
        class="absolute z-10 mt-1"
        :style="{ left: `${widthColumn}px` }"
        @click="$modal.show('predecessor')"
      >
        <base-icon size="16"> <iconAlertCircle /></base-icon>
      </div>
      <chart-gantt
        ref="ganttChart"
        class="board-timeline__gantt v-gantt-chart"
        :chart-data="chartData"
        :show-today="zoom !== 3"
        :config="config"
        :templates="templates"
        :events="events"
        :plugins="{
          tooltip: true
        }"
      />
    </div>
    <modal :name="getCreateModalName" height="auto" @closed="createTaskClosed">
      <div class="p-5">
        <waterfall-board-form-task
          v-model="taskForm"
          :name-modal="getCreateModalName"
        />
        <layout-confirm
          :disabled="createDisabled"
          right
          :confirm-text="$t('create.create')"
          class="mt-5"
          @cancel="toggleCreate"
          @confirm="beforeAddTask"
        />
      </div>
    </modal>
    <modal-task-waterfall
      :loading="loadingActiveTask"
      :task.sync="activeTask"
      @opened="handleTaskOpened"
    />
    <modal ref="predecessor" name="predecessor" height="auto">
      <div class="border-b p-4 px-8 text-lg">
        คำอธิบาย(Predecessor)
      </div>
      <div class="p-8">
        <p>
          1) Finish to Start (FS) ทางาน Successor ให้เสร็จก่อน จึงจะทางาน
          Predecessor เช่น จ่ายค่าจอดรถก่อนนารถเข้าไปจอด เป็นต้น
        </p>
        <p>
          2) Start to Start (SS) งาน Predecessor และSuccessor เริ่มพร้อม ๆ กัน
        </p>
        <p>
          3) Finish to Finish (FF) งาน Predecessor และSuccessor
          เสร็จสิ้นพร้อมกัน เช่น การรับจัดงานแต่งงาน
          โดยงานทุกอย่างต้องเสร็จเมื่องานแตงเริ่ม เป็นต้น
        </p>
        <p>
          4) Start to Finish (SF) งาน Predecessor เริ่มต้นเมื่อ Successor
          เสร็จสิ้น เช่น งาน Successor ของผู้จัดฝึกอบรม (สป.กค.) คือ
          การรวบรวมรายชื่อ ติดต่อวิทยากร เตรียมเอกสารอบรมฯ
          ซึ่งทั้งหมดต้องเรียบร้อยก่อน จึงจะเริ่มฝึกอบรม/การเรียนการสอน เป็นต้น
        </p>
      </div>
    </modal>
    <waterfall-board-activities :active.sync="activityActive" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import GanttChartHeader from '@/mixins/GanttChartHeader';
import DragAndDrop from '@/mixins/DragAndDrop';
import ChartGantt from '@/components/chart/chart-gantt.vue';
import ModalTaskWaterfall from '@/components/modal/modal-task-waterfall';
import LayoutConfirm from '@/components/layout/layout-confirm.vue';
import { tasksApi } from '@/services/apis';
import WaterfallBoardFormTask from '@/components/waterfall-board/waterfall-board-form-task.vue';
import WaterfallBoardActivities from '@/components/waterfall-board/waterfall-board-activities.vue';
import FolderOpenIcon from '@/assets/icons/folder-open.svg';
import FolderClosedIcon from '@/assets/icons/folder-closed.svg';
import RecalculatePosition from '@/mixins/RecalculatePosition';
import iconAlertCircle from '@/components/icons/IconAlertCircle.vue';
const TYPE_UNSELECTED = 0;
const TYPE_GROUP = 1;
const TYPE_TASK = 2;
const ZOOM_MONTH = 1;

const FINISH_TO_FINISH = 1;
const FINISH_TO_START = 2;
const START_TO_FINISH = 3;
const START_TO_START = 4;
const ganttLinkToTask = [
  FINISH_TO_START,
  START_TO_FINISH,
  FINISH_TO_FINISH,
  START_TO_START
];

export default {
  mixins: [GanttChartHeader, DragAndDrop, RecalculatePosition],
  components: {
    ChartGantt,
    ModalTaskWaterfall,
    LayoutConfirm,
    WaterfallBoardFormTask,
    WaterfallBoardActivities,
    iconAlertCircle
  },
  beforeRouteLeave(_0, _1, next) {
    this.offSnapshotFirestore();
    next();
  },
  data() {
    const grid_width = 595;
    const marginFromTextPredecessor = 20;

    const { scales } = this.getZoomConfigs()[ZOOM_MONTH];
    const { start, end } = this.getStartEndDates(undefined, 'month');
    return {
      taskType: TYPE_UNSELECTED,
      taskForm: undefined,
      loadingActiveTask: false,
      activeTask: null,
      activeTaskId: null,
      waitTaskLinks: null,
      activityActive: false,
      widthColumn: grid_width - marginFromTextPredecessor,
      // Gantt chart
      config: {
        scales,
        grid_width,
        columns: this.getGanttColumns(),
        start_date: start,
        end_date: end,
        min_column_width: 40,
        order_branch: 'marker',
        order_branch_free: true,
        scale_height: 33,
        font_width_ratio: 12
      },
      templates: undefined,
      events: {
        onGanttReady: this.onGanttReady,
        onTaskDblClick: this.onTaskDblClick,
        // Task
        onAfterTaskDrag: id => {
          const task = this.gantt.getTask(id);
          this.setGanttTask(task);
          return true;
        },
        onBeforeTaskMove: this.onBeforeTaskMove,
        // Drag & Drop
        onBeforeRowDragMove: this.onBeforeRowDragMove,
        onBeforeRowDragEnd: this.onBeforeRowDragEnd,
        // Links
        onAfterLinkAdd: this.onAfterLinkAdd,
        onAfterLinkDelete: this.onAfterLinkDelete
      }
    };
  },
  computed: {
    ...mapGetters('tasks', [
      'getSortedTasks',
      'getLatestUpdated',
      'getLatestRemoved'
    ]),
    objectTasks() {
      return _.keyBy(this.getSortedTasks, 'id');
    },
    gantt() {
      return this.$refs.ganttChart.gantt;
    },
    tasksLen() {
      return this.getSortedTasks.length;
    },
    chartData() {
      const tasks = this.getSortedTasks.map(this.mapTaskToChartData);
      const links = this.getSortedTasks.reduce(this.reduceTaskToLinks, []);
      return {
        tasks,
        links
      };
    },
    getCreateModalName() {
      return 'waterfall-task-create';
    },

    getTaskModalName() {
      return 'waterfall-task';
    },
    createDisabled() {
      return !this.taskForm?.name;
    },
    taskTypes() {
      return {
        group: TYPE_GROUP,
        task: TYPE_TASK
      };
    }
  },
  mounted() {
    this.handleShowModal();
    const element = this.$refs.ganttChart.$el;
    element.addEventListener('mouseover', this.calculateWidth);
    window.addEventListener('resize', this.calculateWidth);
  },
  beforeDestroy() {
    const element = this.$refs.ganttChart.$el;
    element.removeEventListener('mouseover', this.calculateWidth);
    window.removeEventListener('resize', this.calculateWidth);
  },
  watch: {
    '$route.query.t'() {
      this.handleShowModal();
    },
    waitTaskLinks: _.debounce(function(val) {
      if (!this.waitTaskLinks) return;
      this.setTask(val);
      this.waitTaskLinks = null;
    }, 300),
    getLatestUpdated(latestUpdated) {
      this.activeTask = latestUpdated;
      if (!latestUpdated) return;
      const { links } = this.chartData;
      const task = this.mapTaskToChartData(latestUpdated);
      const isTaskExists = this.gantt.isTaskExists(latestUpdated.id);
      // There is comment about why $source or $target being nessesary http://disq.us/p/2e981i5
      task.$source = links.filter(link => link.source === task.id);
      task.$target = links.filter(link => link.target === task.id);
      if (isTaskExists) this.gantt.updateTask(latestUpdated.id, task);
      else this.gantt.addTask(task);
    },
    getLatestRemoved(latestRemoved) {
      this.activeTask = latestRemoved;

      if (latestRemoved) this.gantt.deleteTask(latestRemoved.id);
    }
  },
  methods: {
    ...mapMutations('tasks', ['UPDATE_TASK', 'UPDATE_TASKS']),
    ...mapActions('tasks', [
      'addTask',
      'fetchTasks',
      'offSnapshotFirestore',
      'setTask'
    ]),
    calculateWidth() {
      const getWidth = document.getElementsByClassName('gantt_grid_scale')[0];
      const getWidthLastCol = document.getElementsByClassName(
        'gantt_grid_head_predecessor'
      )[0];
      const colLast = getWidthLastCol?.clientWidth / 3;
      const marginFromText = 20;
      this.widthColumn = getWidth?.clientWidth + marginFromText - colLast;
    },
    handleShowModal() {
      const routeTask = this.$route.query.t;
      if (!routeTask) return;
      if (routeTask.includes('create')) this.toggleCreate();
      else this.showTask(routeTask);
    },
    showTask(taskId) {
      if (taskId) {
        this.loadingActiveTask = true;
        this.$modal.show(this.getTaskModalName, taskId);
        this.activeTaskId = taskId;
      }
    },
    async handleTaskOpened() {
      this.activeTask = await tasksApi
        .get(this.activeTaskId)
        .then(s => s.data)
        .catch(() => null);
      this.loadingActiveTask = false;
    },
    toggleActivity() {
      this.activityActive = !this.activityActive;
    },
    // * create task
    beforeAddTask() {
      const boardID = parseInt(this.$route.params.id, 10);
      const { startAt, duration, progress, ...taskForm } = this.taskForm;
      const endAt = moment(startAt)
        .add(duration, 'days')
        .toISOString();
      const lastTaskPosition =
        this.getSortedTasks[this.tasksLen - 1]?.position || 0.5;
      const position = lastTaskPosition * 2;
      this.addTask({
        ...taskForm,
        boardID,
        endAt,
        position,
        progressInPercent: progress,
        startAt: startAt.toISOString(),
        type: this.taskType
      });
      this.toggleCreate();
    },
    toggleCreate() {
      const createType = (this.$route.query.t || 'create_').split('_')[1];
      const taskType = ['', 'group', 'task'].indexOf(createType);
      this.taskType = taskType;
      this.taskForm = undefined;
      this.$modal.toggle(this.getCreateModalName);
    },
    showCreate(t) {
      this.$router.push({
        query: {
          t
        }
      });
    },
    createTaskClosed() {
      this.$router.push({ query: { t: undefined } });
    },
    // * gantt chart
    getGanttColumns() {
      const tagText = (classes = 'text-gray-caption') => (
        strings,
        ...allText
      ) => {
        const joined = strings
          .slice(1)
          .map((str, i) => `${allText[i]}${str}`)
          .join(' ');
        return `<p class="truncate prevent-tooltip ${classes}">${strings[0]}${joined}</p>`;
      };
      const spanText = tagText();
      const headerText = tagText('font-semibold');
      const normalText = tagText('font-base');
      const isTypeGroup = taskId =>
        this.objectTasks[taskId]?.type === TYPE_GROUP;
      return [
        {
          name: 'wbs',
          label: this.$t('no'),
          width: 40,
          align: 'center'
        },
        {
          name: 'text',
          label: this.$t('task_name'),
          width: '*',
          resize: true,
          tree: true,
          template: task => {
            return isTypeGroup(task.id)
              ? headerText`${task.text}`
              : normalText`${task.text}`;
          }
        },
        {
          name: 'progress',
          label: this.$t('progress'),
          align: 'center',
          template: ({ progress }) => spanText`${Math.round(progress * 100)}%`
        },
        {
          name: 'duration',
          label: this.$t('duration'),
          align: 'center',
          max_width: 80,
          template: ({ duration }) => spanText`${duration} Days`
        },
        {
          name: 'predecessor',
          label: this.$t('predecessor'),
          width: 90,
          align: 'center'
        }
      ];
    },
    mapTaskToChartData(task) {
      const getDate = date => new Date(date);
      const {
        id,
        name: text,
        startAt,
        endAt,
        progressInPercent,
        groupID = 0,
        type,
        position
      } = task;
      const [start_date, end_date] = [startAt, endAt].map(getDate);
      const progress = progressInPercent / 100;
      const isGroup = type === TYPE_GROUP;
      return {
        id,
        text,
        start_date,
        end_date,
        progress,
        position,
        type: isGroup ? 'project' : 'task',
        parent: groupID,
        open: isGroup,
        data: task
      };
    },
    mapChartDataToTask(chartData) {
      const {
        id,
        text: name,
        start_date: startAt,
        end_date: endAt,
        progress
      } = chartData;

      const progressInPercent = Math.round(progress * 100);
      return {
        id,
        name,
        startAt,
        endAt,
        progressInPercent
      };
    },
    renderTooltipText(start, end, { data: task }) {
      const { name, endAt } = task;
      const createRow = (title, content) => `
        <tr>
          <td class="align-top font-medium p-1">
            ${title}:
          </td>
          <td class="p-1">
            <p class="font-normal whitespace-normal text-2-line" style="-webkit-line-clamp: 2;">
              ${content}
            </p>
          </td>
        </tr>
      `;

      const makeTable = (...rows) =>
        `<table class="table-fixed" style="width: 334px;">
            <thead>
              <tr><th style="width: 80px"></th><th></th></tr>
            </thead>
            ${rows.join('')}
        </table>`;

      const formatDate = date => moment(date).format('DD-MM-YYYY');

      const nameRow = createRow('Task name', name);
      const startRow = createRow('Start date', formatDate(start));
      const endRow = createRow('End date', formatDate(end));
      const tooltipTable = makeTable(nameRow, startRow, endRow);
      return tooltipTable;
    },

    renderGridFolder(item) {
      const icon = item.$open ? FolderOpenIcon : FolderClosedIcon;
      return `
        <div
          class="
            gantt_tree_icon gantt_${item.$open ? 'close' : 'open'}"
          style="background-image: url(${icon}); width: 24px; margin-right: 10px; background-size: contain;"
          >
        </div>
      `;
    },
    // * gantt chart events
    onGanttReady() {
      this.templates = {
        task_class: () => 'timeline__task',
        leftside_text: this.getTextTemplate('left'),
        rightside_text: this.getTextTemplate('right'),
        task_text: this.getTextTemplate('center'),
        tooltip_text: this.renderTooltipText,
        grid_open: this.renderGridFolder,
        grid_indent: () => `<div style="min-width: 34px;"></div>`
      };
    },
    setGroupTask(id, parent) {
      const tasks = this.getSortedTasks;
      const task = tasks.find(t => t.id === parseInt(id, 10));
      const isMovedGroup = task.groupID !== parent;
      if (isMovedGroup) {
        const newGroup = tasks.find(t => t.id === parseInt(parent, 10));
        const shouldSetNewGroup = newGroup && newGroup.type === TYPE_TASK;
        if (shouldSetNewGroup) {
          this.UPDATE_TASK({ id: newGroup.id, type: TYPE_GROUP });
        }

        const oldGroup = tasks.find(t => t.id === task.groupID);
        if (!oldGroup) return;
        const isOldGroupEmpty =
          tasks.filter(t => t.groupID === oldGroup.id).length === 0;
        const shouldSetOldGroup =
          oldGroup.type === TYPE_GROUP && isOldGroupEmpty;
        if (shouldSetOldGroup) {
          this.UPDATE_TASK({ id: oldGroup.id, type: TYPE_TASK });
        }
      }
    },
    setGanttTask(data) {
      const task = this.mapChartDataToTask(data);
      this.setTask(task);
    },
    onTaskDblClick(taskId) {
      if (this.$route.query.t) {
        this.$router.push({
          name: 'Board-Waterfall'
        });
      }
      this.$router.push({
        query: {
          t: taskId
        }
      });
      return false;
    },
    // drag & drop
    onBeforeRowDragMove(current, parent) {
      return current != parent;
    },
    async onBeforeRowDragEnd(current = '0', parent, target) {
      this.setGroupTask(current, parent);
      const NUMBER_OF_REMOVED_ITEM = 1;
      const id = parseInt(current, 10);
      // find a task position in a group
      const relativeTasks = this.getSortedTasks.filter(
        t => t.groupID === parent || !(t.groupID || parent) // new group
      );
      const currentTaskIndex = relativeTasks.findIndex(t => t.id === id);
      // when task is drop onto itself
      if (currentTaskIndex === target) return;
      // find a task from a group or tasks store for new group
      const relativeTask = relativeTasks.splice(
        currentTaskIndex,
        NUMBER_OF_REMOVED_ITEM
      )[0];
      const newGroupTask = this.getSortedTasks.find(task => task.id === id);
      const task = relativeTask || newGroupTask;
      // insert task to get the position
      relativeTasks.splice(target, 0, task);
      const position = this.getPosition(relativeTasks, target);
      // recalculate position in the related group
      if (this.shouldRecalculatePostion(position, relativeTasks)) {
        const recalculatedTasks = this.recalculateItemsPosition(relativeTasks, {
          id,
          position
        });
        this.UPDATE_TASKS(recalculatedTasks);
      }
      // request for update
      const request = { id, position, groupID: parent };
      this.UPDATE_TASK(request);
      this.setTask(request);
    },
    // links
    reduceTaskToLinks(links, task) {
      const { id, predecessors } = task;
      if (!predecessors) return links;
      const hasAllPredecessors = predecessors.every(p =>
        this.getSortedTasks.some(t => t.id === p.taskID)
      );
      if (!hasAllPredecessors) return links;
      return [...links, ...predecessors.map(p => this.parseTaskToLink(id, p))];
    },
    parseTaskToLink(target, taskPredecessor) {
      const { taskID: source, dayAdded: lag } = taskPredecessor;
      const type = ganttLinkToTask.indexOf(taskPredecessor.type).toString();
      return {
        source,
        target,
        lag,
        type
      };
    },
    parseLinkToTask(link) {
      const { source, lag, type } = link;
      return {
        taskID: parseInt(source, 10),
        dayAdded: parseInt(lag, 10),
        type: ganttLinkToTask[type]
      };
    },
    // all link request to api
    getTaskItemOnLink(link) {
      const task = this.waitTaskLinks || this.objectTasks[link.target];
      const item = this.parseLinkToTask(link);
      return { task, item };
    },
    onAfterLinkAdd(_, link) {
      const { task, item } = this.getTaskItemOnLink(link);
      const predecessors = [...(task.predecessors || []), item];
      this.waitTaskLinks = { id: task.id, predecessors };
    },
    onAfterLinkDelete(_, link) {
      const { task, item } = this.getTaskItemOnLink(link);
      const predecessors = task.predecessors.filter(
        p => !(p.taskID === item.taskID && p.type === item.type)
      );
      this.waitTaskLinks = { id: task.id, predecessors };
    }
  }
};
</script>

<style lang="scss">
#waterfall-board {
  .gantt_grid_head_cell {
    @apply text-xs capitalize;
  }

  .gantt_task_line[style*='width: 0px'] {
    @apply border-0;
  }

  .gantt_grid_data {
    @apply border-r;
  }
  .gantt_tree_icon.gantt_blank {
    @apply w-0;
  }

  span.horizontal-dots {
    @apply bg-gray-caption mx-auto inline-block;
    position: relative;
    border-radius: 50%;
    font-size: 0;
    width: 4.5px;
    height: 4.5px;
  }

  span.horizontal-dots:before {
    @apply bg-gray-caption;
    position: absolute;
    left: -8px;
    top: 0;
    content: '';
    border-radius: 50%;
    font-size: 0;
    width: 4.5px;
    height: 4.5px;
  }

  span.horizontal-dots:after {
    @apply bg-gray-caption;
    position: absolute;
    left: 8px;
    top: 0;
    content: '';
    border-radius: 50%;
    font-size: 0;
    width: 4.5px;
    height: 4.5px;
  }
}
.gantt_task_progress {
  background-color: #448aff;
}

.gantt_task_line {
  background-color: #c8e4fe;
}

.gantt_task_line.gantt_project {
  background-color: #99dcb5;
  .gantt_task_progress {
    background-color: #00a846;
  }
}
</style>

<i18n>
{
  "en": {
    "activity": "Activity",
    "search":"Search",
    "no":"No.",
    "task_name":"Task Name",
    "progress":"Progress",
    "duration":"Duration",
    "predecessor":"Predecessor",
    "create_task":"Create task"

  },
  "th":{
    "activity": "กิจกรรม",
    "search":"ค้นหา",
    "no":"เลขที่",
    "task_name":"ชื่องาน",
    "progress":"ความคืบหน้า",
    "duration":"ระยะเวลา",
    "predecessor":"Predecessor",
    "create_task":"สร้าง task"

  }
}
</i18n>
