<template>
  <div class="form-task">
    <div class="form-task__field">
      <base-input-text-growing
        ref="autofocusEl"
        v-model="name"
        rows="1"
        :borderless="blurred && name !== ''"
        :placeholder="$t('name')"
        class="fond-bold mb-3"
        @blur="blurred = true"
        @focus="blurred = false"
      />
    </div>
    <div class="form-task__field">
      <icon-header-add icon="description">
        {{ $t('description') }}
      </icon-header-add>
      <base-input-text-growing
        v-model="description"
        size="text-sm"
        :min-height="90"
        :placeholder="$t('modelTask.task.add_a_description') + '...'"
      />
    </div>
    <div class="form-task__field flex">
      <div class="items-center">
        <p class="form-task__label">
          {{ $t('period') }}
        </p>
        <select-date
          v-model="startAt"
          :disabled="dateDisabled"
          :limit-today="false"
          modal
          class="mr-5"
          :task="task"
          :name-modal="nameModal"
          @endDate="getEndDate"
        />
        <!--    <v-numeric-input
          v-model="duration"
          :disabled="dateDisabled"
          :min="1"
          align="center"
        />
        <div class="text-sm">
          <span class="ml-2">
            {{ $tc('day', duration) }}
          </span>
          <span class="ml-1">
            {{ displayEndDate }}
          </span>
        </div>-->
      </div>

      <div v-if="!isCreateTask">
        <p class="label--light">
          {{ $t('modelTask.task.tags') }}
        </p>
        <select-task-tags ref="taskTags" class="p-1" :lines="0" :task="task" />
      </div>
    </div>

    <div class="form-task__field">
      <div class="form-task__label">
        {{ $t('progress') }}
        : {{ progressText }}
      </div>
      <div class="px-4">
        <base-input-progress v-model="progress" />
      </div>
    </div>
  </div>
</template>

<script>
// import VNumericInput from 'vue-numeric-input';
import moment from 'moment';
import IconHeaderAdd from '../icon-header-add.vue';
import SelectDate from '../select/select-date.vue';
import SelectTaskTags from '@/components/select/select-task-tags.vue';

export default {
  props: {
    value: {
      type: Object,
      default: () => {
        const startAt = moment()
          .startOf('d')
          .toDate();
        return {
          startAt,
          duration: 1,
          name: '',
          description: '',
          progress: 0
        };
      }
    },
    task: Object,
    dateDisabled: Boolean,
    nameModal: String
  },
  components: { IconHeaderAdd, SelectDate, SelectTaskTags },
  mounted() {
    this.autofocusEl.textareaInput.$el.focus();
  },
  data() {
    return {
      blurred: true,
      duration: 1,
      startAt: null,
      name: '',
      description: '',
      progress: 0
    };
  },
  computed: {
    progressText() {
      return `${this.progress}/100`;
    },
    watchValue() {
      return {
        startAt: this.startAt,
        duration: this.duration,
        name: this.name,
        description: this.description,
        progress: this.progress
      };
    },
    autofocusEl() {
      return this.$refs.autofocusEl;
    },
    displayEndDate() {
      return moment(this.startAt)
        .add(this.duration, 'days')
        .add(this.$i18n.locale === 'th' ? 543 : 0, 'years')
        .format('LL');
    },
    isCreateTask() {
      return this.nameModal == 'waterfall-task-create';
    }
  },
  watch: {
    value: {
      handler(value) {
        const { startAt, duration, name, description, progress } = value;
        this.startAt = startAt;
        this.duration = duration;
        this.name = name;
        this.description = description;
        this.progress = progress;
      },
      immediate: true
    },
    watchValue(value, prev) {
      this.$emit('input', value);

      const changedProp = Object.keys(value).find(key => {
        const currentValue = value[key] ?? '';
        const prevValue = prev[key] ?? '';
        if (key === 'startAt') {
          return !moment(currentValue).isSame(prevValue, 'day');
        }
        return currentValue.toString().localeCompare(prevValue.toString());
      });

      if (changedProp) {
        const changed = { [changedProp]: value[changedProp] };
        this.$emit('changed', changed);
      }
    }
  },
  methods: {
    getEndDate(val) {
      const diffDay = moment(val).diff(moment(this.startAt), 'days');
      this.duration = diffDay;
    }
  }
};
</script>

<style>
#waterfall-board .vue-numeric-input {
}
#waterfall-board .vue-numeric-input .numeric-input {
  @apply border-gray-main-dark rounded border outline-none;
  height: 36px;
}

#waterfall-board .vue-numeric-input .btn {
  width: 2.25rem;
}

#waterfall-board .vue-numeric-input .btn-icon::before,
#waterfall-board .vue-numeric-input .btn-icon::after {
  @apply bg-brand-blue;
}
#waterfall-board .vue-numeric-input .btn-increment {
  @apply bg-transparent rounded-none shadow-none border-transparent;
  top: 1px;
  right: 1px;
  bottom: 1px;
}

#waterfall-board .vue-numeric-input .btn-decrement {
  @apply left-0 top-0 bottom-0 bg-transparent rounded-none shadow-none border-transparent;
  top: 1px;
  left: 1px;
  bottom: 1px;
}
#waterfall-board .vue-numeric-input .btn:hover {
  @apply bg-light-gray cursor-pointer rounded;
}
#waterfall-board .vue-numeric-input .btn-increment::before,
#waterfall-board .vue-numeric-input .btn-decrement::after {
  @apply border-l border-gray-main-dark absolute;
  content: '';
  top: -1px;
  bottom: -1px;
}
#waterfall-board .vue-numeric-input .btn-increment::before {
  left: -1px;
}
#waterfall-board .vue-numeric-input .btn-decrement::after {
  right: -1px;
}
#waterfall-board .vue-numeric-input .btn-increment .btn-icon::before,
#waterfall-board .vue-numeric-input .btn-decrement .btn-icon::before {
  height: 0.1rem;
  width: 40%;
}
#waterfall-board .vue-numeric-input .btn-increment .btn-icon::after {
  height: 40%;
  width: 0.1rem;
}

.form-task__field:not(:first-of-type) {
  @apply mt-5;
}
.form-task__label {
  @apply font-medium mb-2;
}
</style>
