import Vue from 'vue';
import { GanttConfigOptions, GanttStatic } from 'dhtmlx-gantt';
import moment, { unitOfTime } from 'moment';
import VueI18n from 'vue-i18n';

interface FormatFn {
  (date: Date): string;
}
interface Level {
  scales: {
    unit: string;
    step: number;
    format: string | FormatFn;
  }[];
}

type Zoom = {
  value: number;
  label: VueI18n.TranslateResult;
  unit: unitOfTime.Base;
};

type ZoomConfig = {
  scales: { unit: string; format: string; step: number }[];
  start: Date;
  end: Date;
};

// 1.1 Waterfall is using via mixins/GanttChartHeader
// 1.2 Timeline is using directly

export default Vue.extend({
  data() {
    return {
      zoom: 2,
      isZoomToFit: false,
      paginationDate: new Date(),
      config: {} as GanttConfigOptions
    };
  },
  computed: {
    refGantt(): GanttStatic {
      return (this.$refs.ganttChart as { gantt: GanttStatic } & Vue).gantt;
    },
    zoomOptions(): Zoom[] {
      const getLabel = (zoom: string) => {
        const localePath = ['timeline', 'date', zoom].join('.');
        return this.$t(localePath);
      };
      const units: unitOfTime.Base[] = ['week', 'month', 'year'];
      return units.map((unit, i) => ({
        unit,
        value: i + 1,
        label: getLabel(unit)
      }));
    },
    paginationUnit(): unitOfTime.StartOf {
      return this.zoomOptions[this.zoom - 1].unit;
    },
    levels(): Level[] {
      const gantt = this.refGantt;
      return [
        // hours
        {
          scales: [
            { unit: 'day', step: 1, format: '%d %M' },
            { unit: 'hour', step: 1, format: '%H:%i' }
          ]
        },
        // days
        {
          scales: [{ unit: 'day', step: 1, format: '%d %M' }]
        },
        // weeks
        {
          scales: [
            {
              unit: 'week',
              step: 1,
              format: function(date: Date) {
                const dateToStr = gantt.date.date_to_str('%d %M');
                const endDate = gantt.date.add(date, -6, 'day');
                const weekNum = gantt.date.date_to_str('%W')(date);
                return (
                  '#' +
                  weekNum +
                  ', ' +
                  dateToStr(date) +
                  ' - ' +
                  dateToStr(endDate)
                );
              }
            },
            { unit: 'day', step: 1, format: '%j %D' }
          ]
        },
        // months
        {
          scales: [
            { unit: 'month', step: 1, format: '%F, %Y' },
            {
              unit: 'week',
              step: 1,
              format: function(date: Date) {
                const dateToStr = gantt.date.date_to_str('%d %M');
                const endDate = gantt.date.add(
                  gantt.date.add(date, 1, 'week'),
                  -1,
                  'day'
                );
                return dateToStr(date) + ' - ' + dateToStr(endDate);
              }
            }
          ]
        },
        // quarters
        {
          scales: [
            {
              unit: 'quarter',
              step: 3,
              format: function(date: Date) {
                const dateToStr = gantt.date.date_to_str('%M %Y');
                const endDate = gantt.date.add(
                  gantt.date.add(date, 3, 'month'),
                  -1,
                  'day'
                );
                return dateToStr(date) + ' - ' + dateToStr(endDate);
              }
            },
            { unit: 'month', step: 1, format: '%M' }
          ]
        },
        // years
        {
          scales: [
            {
              unit: 'year',
              step: 5,
              format: function(date: Date) {
                const dateToStr = gantt.date.date_to_str('%Y');
                const endDate = gantt.date.add(
                  gantt.date.add(date, 5, 'year'),
                  -1,
                  'day'
                );
                return dateToStr(date) + ' - ' + dateToStr(endDate);
              }
            }
          ]
        },
        // decades
        {
          scales: [
            {
              unit: 'year',
              step: 100,
              format: function(date: Date) {
                const dateToStr = gantt.date.date_to_str('%Y');
                const endDate = gantt.date.add(
                  gantt.date.add(date, 100, 'year'),
                  -1,
                  'day'
                );
                return dateToStr(date) + ' - ' + dateToStr(endDate);
              }
            },
            {
              unit: 'year',
              step: 10,
              format: function(date: Date) {
                const dateToStr = gantt.date.date_to_str('%Y');
                const endDate = gantt.date.add(
                  gantt.date.add(date, 10, 'year'),
                  -1,
                  'day'
                );
                return dateToStr(date) + ' - ' + dateToStr(endDate);
              }
            }
          ]
        }
      ];
    }
  },
  created() {
    this.paginationDate = this.getStartEndDates(
      null,
      this.paginationUnit
    ).start;
  },
  watch: {
    paginationDate: 'setGanttDate'
  },
  methods: {
    getStartEndDates(date: moment.MomentInput, unit: unitOfTime.StartOf) {
      const _date = moment(date || new Date()).locale(this.$i18n.locale);
      return {
        start: _date.startOf(unit).toDate(),
        end: _date.endOf(unit).toDate()
      };
    },
    updateConfig(config: Partial<GanttConfigOptions>) {
      this.config = { ...this.config, ...config };
    },
    setGanttDate<T = Date>(date: T, prev?: T | boolean) {
      if (!prev) return '';
      const { start, end } = this.getStartEndDates(date, this.paginationUnit);
      // Waterfall & Timeline are using different config
      const { scales } = this.getZoomConfigs()[this.zoom - 1];
      this.updateConfig({
        fit_tasks: false,
        start_date: start,
        end_date: end,
        scales
      });
    },
    getZoomConfigs(): ZoomConfig[] {
      return [];
    },
    // Implemented from https://docs.dhtmlx.com/gantt/desktop__configuring_time_scale.html#range
    getZoomToFitStartEndDates(): (Date | undefined)[] {
      const gantt = this.refGantt;
      const range = gantt.getSubtaskDates();
      if (!(range.start_date && range.end_date)) return [];
      const scaleUnit = gantt.getState().scale_unit;
      const start_date = gantt.calculateEndDate({
        start_date: range.start_date,
        duration: -4,
        unit: scaleUnit
      });
      const end_date = gantt.calculateEndDate({
        start_date: range.end_date,
        duration: 5,
        unit: scaleUnit
      });
      return [start_date, end_date];
    },
    getUnitsBetween(from: Date, to: Date, unit: string, step: number) {
      let start = new Date(from);
      const end = new Date(to);
      let units = 0;
      while (start.valueOf() < end.valueOf()) {
        units++;
        start = this.refGantt.date.add(start, step, unit);
      }
      return units;
    },
    zoomToFit() {
      if (this.isZoomToFit) {
        this.setGanttDate(this.paginationDate, true);
      } else {
        // Ref > view-source:https://docs.dhtmlx.com/gantt/samples/03_scales/13_zoom_to_fit.html
        const gantt = this.refGantt;
        const project = gantt.getSubtaskDates();
        const areaWidth = (gantt as GanttStatic & {
          $task: { offsetWidth: number };
        }).$task.offsetWidth;
        const scaleConfigs = this.levels;
        let i;

        for (i = 0; i < scaleConfigs.length; i++) {
          const columnCount = this.getUnitsBetween(
            project.start_date,
            project.end_date,
            scaleConfigs[i].scales[scaleConfigs[i].scales.length - 1].unit,
            scaleConfigs[i].scales[0].step
          );
          if ((columnCount + 2) * gantt.config.min_column_width <= areaWidth) {
            break;
          }
        }

        if (i == scaleConfigs.length) i--;
        const [start_date, end_date] = this.getZoomToFitStartEndDates();
        this.updateConfig({
          fit_tasks: true,
          start_date,
          end_date,
          scales: this.levels[i].scales
        });
      }
      this.isZoomToFit = !this.isZoomToFit;
    }
  }
});
