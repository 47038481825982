<template>
  <div class="board-detail" :class="{ active }">
    <div v-on-clickaway="hide" class="content task-activities text-sm">
      <v-scroller :style="{ height: '100%' }">
        <header
          class="flex items-center mx-5 border-b mb-4 sticky top-0 bg-white"
          :style="{ height: `${headerHeight}px` }"
        >
          <span class="mt-4 font-medium text-xl">Activity</span>
        </header>
        <div>
          <ul class="-my-2 activities-list">
            <li
              v-for="(activities, key) in activityDates"
              :key="key"
              class="pb-2 activities-list__item"
            >
              <p
                class="sticky bg-white z-10 font-semibold py-2 px-5 mb-3"
                :style="{ top: `${headerHeight}px` }"
              >
                {{ getCalendarDate(key) }}
              </p>
              <ul class="-my-2">
                <li
                  v-for="activity in activities"
                  :key="activity.id"
                  class="px-5 py-2 hover:bg-light-gray cursor-pointer"
                  @click="openActivity(activity)"
                >
                  <info-user
                    :user="getUser(activity)"
                    size="small"
                    align="items-start"
                  >
                    <p slot="user" class="truncate font-medium">
                      {{ getUser(activity).name }}
                    </p>
                    <p
                      class="text-gray-caption text-2-line"
                      v-html="markdownText(activity[localeDescription])"
                    />
                  </info-user>
                  <p class="text-right text-gray-caption text-xs">
                    {{ getDateFormat(activity.createdAt, 'D MMM, HH:mm') }}
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </v-scroller>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import MarkdownTextMixin from '@/mixins/MarkdownText';
import QueryList from '@/mixins/QueryListMixin';
import DateTime from '@/mixins/DateTimeMixin';
import VScroller from '@/components/scroller';
import { boardsApi } from '@/services/apis';
import InfoUser from '../info/info-user.vue';
import { mapGetters } from 'vuex';

// src/assets/styles/style.scss - Ln 669
const TRANSITION_TIME = 400;

export default {
  directives: { onClickaway },
  mixins: [QueryList, DateTime, MarkdownTextMixin],
  components: { VScroller, InfoUser },
  props: {
    active: Boolean
  },
  data() {
    return {
      shouldClickaway: false,
      headerHeight: 54.75
    };
  },
  computed: {
    ...mapGetters('tasks', ['getLatestUpdated']),
    activityDates() {
      return this.groupItemByCreatedAt(this.queryList);
    },
    localeDescription() {
      const PREFIX = 'description';
      const locale = this.$i18n.locale.toUpperCase();
      return PREFIX + locale;
    },
    shouldClickawayDeps() {
      return {
        active: this.active,
        task: this.$route.query.t
      };
    }
  },
  watch: {
    getLatestUpdated(latestUpdated) {
      if (latestUpdated) this.handleNewActivities();
    },
    shouldClickawayDeps({ active, task }) {
      setTimeout(
        () => (this.shouldClickaway = !task && active),
        TRANSITION_TIME
      );
    }
  },
  methods: {
    async fetchNewActivities() {
      return boardsApi.logs(this.boardID, { page: 1, type: 1 });
    },
    async handleNewActivities() {
      const hasFirstItemInExitedList = updatedItem => {
        return this.queryList.some(item => item.id === updatedItem.id);
      };
      const {
        data: { entities }
      } = await this.fetchNewActivities();
      const first = entities.findIndex(hasFirstItemInExitedList);
      const end = first < 0 ? undefined : first;
      this.queryList.unshift(...entities.slice(0, end));
    },
    queryFetcher({ params: { boardID, ...params } }) {
      params.type = 1;
      return boardsApi.logs(boardID, params);
    },
    getUser(activity) {
      const {
        userDisplayName: name,
        userAvatarPath: profilePicture
      } = activity;
      return {
        name,
        profilePicture
      };
    },
    hide() {
      if (this.shouldClickaway) this.$emit('update:active', false);
    },
    openActivity(activity) {
      this.$router.push({
        query: {
          t: activity.taskID
        }
      });
    }
  }
};
</script>

<style lang="scss">
.task-activities {
  width: 359px;
  .activities-list__item {
    b {
      @apply text-black font-medium;
    }
  }
}
</style>
