import Vue from 'vue';
import moment, { unitOfTime } from 'moment';
import WrapperPaginationDate from '@/components/wrapper/wrapper-pagination-date.vue';
import { GanttStatic } from '@/libs/gantt_7/codebase/dhtmlxgantt';
import { debounce } from 'lodash';
import GanttUtils from './GanttUtils';

interface GanttChart {
  gantt: GanttStatic;
}

interface ZoomConfig {
  scales: { unit: string; format: string; step: number }[];
  start: Date;
  end: Date;
}

type VGantt = Vue & GanttChart;

export default GanttUtils.extend({
  components: {
    WrapperPaginationDate
  },
  computed: {
    ganttChart(): VGantt {
      return this.$refs.ganttChart as VGantt;
    },
    paginationFormat(): string {
      const month = 'MMMM';
      const year = 'YYYY';
      const formatAll = [month, year];
      if (this.zoom > 2) formatAll.shift();
      return formatAll.join(' ');
    },
    queryString: {
      get(): string {
        return (this.$route.query.q as string) || '';
      },
      set: debounce(function(this: Vue, q) {
        this.$router.push({ query: { ...this.$route.query, q } });
      }, 200)
    }
  },
  methods: {
    getZoomConfigs(): ZoomConfig[] {
      const today = moment().locale(this.$i18n.locale);
      const scaleCss = 'gantt-scale-cell';
      const scaleTodayCss = 'gantt-scale-cell--today';
      const weekendCss = 'gantt-cell--weekend';

      const dayStyle = (initCss?: string) => (date: Date) => {
        const css = [initCss, scaleCss];
        const isToday = moment(date)
          .locale(this.$i18n.locale)
          .isSame(moment().locale(this.$i18n.locale), 'day');
        const isWeekend = date.getDay() === 0 || date.getDay() === 6;
        if (isToday) css.push(scaleTodayCss);
        else if (isWeekend) css.push(weekendCss);
        return css.join(' ');
      };

      const css = dayStyle();
      const step = 1;
      const getScale = (unit: string, format: string) => ({
        unit,
        format,
        step,
        css
      });

      const dayConfig = {
        ...this.getStartEndDates(today, 'week'),
        scales: [getScale('day', '%j %l')]
      };
      const monthConfig = {
        ...this.getStartEndDates(today, 'month'),
        scales: [getScale('day', '%d'), getScale('day', '%D')]
      };
      const yearConfig = {
        ...this.getStartEndDates(today, 'year'),
        scales: [{ unit: 'month', format: '%F', step: 1 }]
      };
      return [dayConfig, monthConfig, yearConfig];
    },
    getTextTemplate(position: string) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const textTemplate = (_0: Date, _1: Date, task: any) => {
        if (this.getTaskFitValue(task) === position) {
          return `<div class="${this.getMarginOffset(position)}">${
            task.text
          }</div>`;
        }
        return '';
      };
      return textTemplate;
    },
    getMarginOffset(position: string) {
      switch (position) {
        case 'left':
          return '-mr-3';
        case 'right':
          return '-ml-3';
        default:
          return '';
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getTaskFitValue(task: any) {
      const gantt = this.ganttChart.gantt;
      const taskStartPos = gantt?.posFromDate(task.start_date),
        taskEndPos = gantt?.posFromDate(task.end_date);

      const width = taskEndPos - taskStartPos;
      const textWidth = ((task.text || '').length *
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (gantt.config as any).font_width_ratio) as number;

      if (width < textWidth) {
        const ganttLastDate = gantt.getState().max_date;
        const ganttEndPos = gantt?.posFromDate(ganttLastDate);
        if (ganttEndPos - taskEndPos < textWidth) {
          return 'left';
        } else {
          return 'right';
        }
      } else {
        return 'center';
      }
    }
  }
});
